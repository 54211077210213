// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-8-1!../node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--6-oneOf-8-1!../node_modules/@blueprintjs/core/lib/css/blueprint.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "body {\n  text-transform: none;\n  line-height: 1.28581;\n  letter-spacing: 0;\n  font-size: 14px;\n  font-weight: 400;\n  color: #182026;\n  font-family: -apple-system, \"BlinkMacSystemFont\", \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Open Sans\", \"Helvetica Neue\", sans-serif;\n}\n.card {\n  border-radius: 3px;\n  box-shadow: 0 0 0 1px rgba(16,22,26,0.15), 0 0 0 rgba(16,22,26,0), 0 0 0 rgba(16,22,26,0);\n  background-color: #fff;\n  padding: 20px;\n}\nimg {\n  max-width: 500px;\n}\n", ""]);
// Exports
module.exports = exports;
